.portfolio__container
{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-right: 20rem;
    margin-left: 20rem;
    gap: 2rem;
}


.portfolio__item
{
    border: 1px solid transparent;
    justify-content: center;
}

.portfolio__item:hover
{
    background: transparent;
}

.portfolio__item h3
{
    margin: 1.2rem 0 2rem;
    font-size: 1rem;
}

.portfolio__item-cta
{
    display: flex;
    margin:0 auto;
    gap: 1rem;
}

.portofolio__image-image
{
    border-radius: 2rem;
    overflow: hidden;
    transition: var(--transition);
}

.portofolio__image-image:hover
{
    transform: scale(1.05);
}

.social__media
{
    text-align: center;
    margin-right: 10rem;
    margin-left: 10rem;
    margin-bottom: 2rem;
}

/*===============Media Queries (Medium Devices) ===================*/
@media screen and (max-width:1024px) 
{
    .portfolio__container
    {
        grid-template-columns: 1fr;
    }
    .portfolio__container > div
    {
        width: 80%;
        padding: 1rem;
        margin: 0 auto;
    }

    .portfolio__content
    {
        padding: 1rem;
        row-gap: .5rem;
    }

    .portfolio__item-image
    {
        max-height: fit-content;
    }   
}

/*===============Small Queries (Medium Devices) ===================*/
@media screen and (max-width:600px) 
{
    .portfolio__container{
        grid-template-columns:  1fr;
       }
    
}