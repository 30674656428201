.skills__container
{
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
    padding: 0 20%;
}

.skills__container > div
{
    background: var(--color-bg-variant);
    padding: 2.4rem 3rem;
    border-radius: 2rem;
    border:1px solid transparent; 
    transition: var(--transition);
}


.skills__container > div:hover
{
    background: transparent;
    border-color: var(--color-primary-variant);
    cursor: default;
}

.skills__container > div h3
{
    text-align: center;
    margin-bottom: 2rem;
    color: var(--color-primary);
}

.skills__content
{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    text-align: center;
    row-gap: 2rem;
    column-gap: 1.5rem;
}

.skills__details
{
    display: flex;
    gap: 1rem;
}


.skills__details-icon
{
    position: relative;
    top: 2rem;
    right: 3.2rem;
    color: var(--color-primary);
}

/*===============Media Queries (Medium Devices) ===================*/
@media screen and (max-width:1024px) 
{
    .skills__container
    {
        grid-template-columns: 1fr;
        margin-left: auto;
        margin-right: auto;
    }
    .skills__container > div
    {
        width: 80%;
        padding: 1rem;
        margin: 0 auto;
    }

    .skills__content
    {
        padding: 1rem;
        row-gap: .5rem;
        column-gap: 1rem;
    }

    .skills__details-icon
    {
        right: 3rem;
    }

    
}

/*===============Small Queries (small Devices) ===================*/
@media screen and (max-width:600px) 
{
   .skills__container
   {
        
        gap: 1rem;
   }

   .skills__container > div
   {
        width: 100%;
        padding: 1rem 1rem;
   }

   .skills__content
    {
        padding: 0;
    }

}