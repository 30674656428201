.contact__container
{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 5%;
}

.contact__options
{
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.contact__option{
    background: var(--color-bg-variant);
    padding: 3rem;
    border-radius: 1.2rem;
    text-align: center;
    border: 1px solid transparent;
    transition: var(--transition);
}

.contact__option:hover
{
    background: transparent;
    border-color: var(--color-primary-variant);
}

.contact__option-icon
{
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}

.contact__option a
{
    margin-top: .7rem;
    display: inline-block;
    font-size: .8rem;
}

.contact__me
{
    width: 60%;
    border-radius: 2rem;
    display: grid;
    place-items: center;
    margin:  0 auto 4rem auto;
}

.contact__me-image
{
    border-radius: 2rem;
    overflow: hidden;
}


/*===============Media Queries (Medium Devices) ===================*/
@media screen and (max-width:1024px) 
{
    .contact__container
    {
        grid-template-columns: 1fr
    }

    .contact__option{
        margin: 0 10rem;
    }

}

/*===============Small Queries (small Devices) ===================*/
@media screen and (max-width:600px) 
{
    .contact__option{
        margin: 0 5rem;
    }
}